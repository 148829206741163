div.wrapperDiv {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
span.loaderSpan {
  height: 30px;
  width: 7px;
  border-radius: 4px;
  margin-right: 10px;
  background-color: var(--base-heading-text-color);
  animation: loading 1s linear infinite;
}
span.loaderSpan:nth-child(1) {
  animation-delay: 0.1s;
}
span.loaderSpan:nth-child(2) {
  animation-delay: 0.2s;
}
span.loaderSpan:nth-child(3) {
  animation-delay: 0.3s;
}
span.loaderSpan:nth-child(4) {
  animation-delay: 0.4s;
}
span.loaderSpan:nth-child(5) {
  animation-delay: 0.5s;
}

@keyframes loading {
  0% {
    height: 0;
  }
  25% {
    height: 25px;
  }
  50% {
    height: 50px;
  }
  100% {
    height: 0;
  }
}
